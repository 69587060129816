<template>
  <div class="bgColor">
    <div class="bg">
      <div class="content">
        <div class="footerTitle">

          <div class="footerMain">
            <div class="footerRight">
              <span class="bigTextColor">{{ footerTitle }}</span>
              <div class="footerBody smallTextColor" v-html="footerDes">
              </div>
              <div class="footerBottom smallTextColor footerBody" v-html="footerBottom">
              </div>

            </div>
            <div class="footerLeft">
              <div class="_contactInformation">
                <div class="contactInformation bigTextColor" v-for="(item, i) in contactInformation" :key="i">
                  <span style="color: white;cursor: pointer" @click="trun(item.src)">{{
                      item.title
                    }}</span>

                </div>
              </div>

            </div>

          </div>


        </div>
        <div class="_footerBottom">
          <span class="turn" @click="trun('/privacy/privacy.html')">Privacy Policy</span> | <span class="turn"
                                                                                                  @click="trun('/terms/terms.html')">Terms of Use</span>
          |
          &nbsp;&nbsp;<span>© 2023 Aivocado Inc All Rights Reserved</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "web-footer",
  data() {
    return {
      footerTitle: 'Contact Us',
      footerDes: 'Feel free to contact us via <a href="mailto:support@aivocado.online" style="color: white">Email</a> if you have some feedback' +
                 ' Twitter ' +
                 'Instagram',
      footerBottom: "",
      contactInformation: [
        {
          title: "Reddit",
          src: 'https://www.reddit.com/r/Aivocado/'
        },
        {
          title: "Discord",
          src: 'https://discord.gg/DeZ7Jtpq'
        },
        {
          title: "Twitter",
          src: 'https://twitter.com/AivocadoApp'
        },
        {
          title: "Instagram",
          src: 'https://instagram.com/aivocado_official?igshid=NTc4MTIwNjQ2YQ=='
        }
      ]
    }
  },
  methods: {
    trun(src) {
      window.open(src, '_blank');
    }
  }
}
</script>

<style scoped lang="less">
.bgColor {
  background: #47954c;
}

.bg {
  height: auto;
  padding-bottom: 20px;
}

.footerMain {
  display: flex;
}

.footerRight, .footerLeft {
  position: relative;
  flex: 1;
}

.footerTitle {
  font-size: 38px;
  font-weight: 400;
  letter-spacing: 1.08px;
  line-height: 70px;
  text-align: left;
  vertical-align: top;
  margin: 30px 0px;
}

.footerBody {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.42px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  margin-top: 20px;
  width: 80%;

}

.footerLeft {

  .bigTextColor {
    font-size: 20px !important;
  }

}

.footerBottom {
  margin-top: 30px;
  width: 80%;
}

._contactInformation {
  width: auto;
  position: absolute;
  right: 0px;
}

.contactInformation {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 1.08px;
  line-height: 24px;
  text-decoration-line: underline;
  text-align: left;
  vertical-align: top;
  margin-top: 10px;
  width: 100%;
}

.bigTextColor {
  color: white;

}

.smallTextColor {
  color: white;
}

._footerBottom {
  text-align: center;
  color: white;

  .turn {
    color: white;
    padding: 0px 20px;
    cursor: pointer;
  }
}

@media (max-width: 992px) {
  .footerTitle {
    font-size: 38px !important;
  }
}
</style>